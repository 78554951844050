<template>
  <div class="verify-phone">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card" v-if="! otp_sent">
          <div class="card-body">
            <div class="text-center">
              <img src="/logo.png" alt="logo" height="100">
            </div>
            <h4>Verify your phone number</h4>
            <p>Your phone number helps us keep your account secure by adding an additional layer of verification.</p>
            <form @submit.prevent="requestOtp" class="mt-4">
              <div class="form-group">
                <label>Phone Number</label>
                <input type="text" required v-model="phone_number" placeholder="0712345678" class="form-control">
              </div>
              <div class="form-group text-end mt-5">
                <a href="#" @click.prevent="skipOtp" class="float-start">Skip</a>
                <button class="btn btn-primary rounded">Request OTP</button>
              </div>
            </form>
          </div>
        </div>
        <div class="card" v-if="otp_sent">
          <div class="card-body">
            <div class="text-center">
              <img src="/logo.png" alt="logo" height="100">
            </div>
            <h4>Verify your phone number</h4>
            <p>We’ve sent a code to <strong>{{ phone_number }}</strong>. Enter the code below.</p>
            <div class="alert alert-danger" v-if="has_error">Invalid code. Try again.</div>
            <form @submit.prevent="verifyOtp" class="mt-4">
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label>Enter Code</label>
                    <input type="text" required v-model="otp" placeholder="XXXX" class="form-control">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="text-white">.</label>
                    <a href="#" class="btn btn-secondary d-block rounded">Resend Code</a>
                  </div>
                </div>
              </div>
              <div class="form-group text-end mt-5">
                <a href="#" @click.prevent="otp_sent=false" class="float-start">Back</a>
                <button class="btn btn-primary rounded">Confirm Code</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/theme.js'

export default {
  data() {
    return {
      phone_number: '',
      otp_sent: false,
      otp: '',
      has_error: false
    }
  },

  methods: {
    requestOtp() {
      let data = {
        phone_number: this.phone_number
      }

      this.$axios.post('/api/v1/request-otp', data).then(() => {
        this.otp_sent = true
      })
    },

    verifyOtp() {
      this.has_error = false

      let data = {
        otp: this.otp
      }

      this.$axios.post('/api/v1/verify-otp', data).then(() => {
        this.$router.push({ name: 'home' })
      }).catch(() => {
        this.has_error = true
      })
    },

    skipOtp() {
      this.$axios.post('/api/v1/skip-otp').then(() => {
        this.$router.push({ name: 'home' })
      })
    }
  }
}
</script>